// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-job-js": () => import("./../../../src/pages/apply-job.js" /* webpackChunkName: "component---src-pages-apply-job-js" */),
  "component---src-pages-blog-article-js": () => import("./../../../src/pages/blog/article.js" /* webpackChunkName: "component---src-pages-blog-article-js" */),
  "component---src-pages-blog-event-js": () => import("./../../../src/pages/blog/Event.js" /* webpackChunkName: "component---src-pages-blog-event-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-info-tania-js": () => import("./../../../src/pages/blog/InfoTania.js" /* webpackChunkName: "component---src-pages-blog-info-tania-js" */),
  "component---src-pages-blog-insight-js": () => import("./../../../src/pages/blog/Insight.js" /* webpackChunkName: "component---src-pages-blog-insight-js" */),
  "component---src-pages-blog-news-js": () => import("./../../../src/pages/blog/News.js" /* webpackChunkName: "component---src-pages-blog-news-js" */),
  "component---src-pages-blog-press-release-js": () => import("./../../../src/pages/blog/Press Release.js" /* webpackChunkName: "component---src-pages-blog-press-release-js" */),
  "component---src-pages-blog-promo-js": () => import("./../../../src/pages/blog/Promo.js" /* webpackChunkName: "component---src-pages-blog-promo-js" */),
  "component---src-pages-careers-detail-js": () => import("./../../../src/pages/careers-detail.js" /* webpackChunkName: "component---src-pages-careers-detail-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-community-detail-js": () => import("./../../../src/pages/community-detail.js" /* webpackChunkName: "component---src-pages-community-detail-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dokter-tania-js": () => import("./../../../src/pages/dokter-tania.js" /* webpackChunkName: "component---src-pages-dokter-tania-js" */),
  "component---src-pages-id-contact-us-js": () => import("./../../../src/pages/id/contact-us.js" /* webpackChunkName: "component---src-pages-id-contact-us-js" */),
  "component---src-pages-id-dokter-tania-js": () => import("./../../../src/pages/id/dokter-tania.js" /* webpackChunkName: "component---src-pages-id-dokter-tania-js" */),
  "component---src-pages-id-index-js": () => import("./../../../src/pages/id/index.js" /* webpackChunkName: "component---src-pages-id-index-js" */),
  "component---src-pages-id-know-your-soil-js": () => import("./../../../src/pages/id/know-your-soil.js" /* webpackChunkName: "component---src-pages-id-know-your-soil-js" */),
  "component---src-pages-id-what-we-do-js": () => import("./../../../src/pages/id/what-we-do.js" /* webpackChunkName: "component---src-pages-id-what-we-do-js" */),
  "component---src-pages-id-who-we-are-js": () => import("./../../../src/pages/id/who-we-are.js" /* webpackChunkName: "component---src-pages-id-who-we-are-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-know-your-soil-js": () => import("./../../../src/pages/know-your-soil.js" /* webpackChunkName: "component---src-pages-know-your-soil-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

